
import { mapGetters } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
export default {
    name: 'LoginPage',
    mixins: [fingerprint, ReCaptcha],
    data: () => ({
        modal: true,
        loginType: 'phone',
        showPassword: false,
        form: {},
        loading: false,
        rememberMe: true,
        country: 'LA',
        showAccountHolderNameInstruction: false,
        accountHolderLoading: false,
        accountNameReadonly: true,
        suspendRegistrationLoading: false,
        registrationOptionLoading: false,
        currencyIsSuspended: false,
        registrationOption: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank
        currency: [],
        isConditional: false,
        acceptCondition: true,
    }),
    computed: {
        ...mapGetters({
            banks: 'banks/banks',
            supportCountries: 'themes/settings/supportCountry',
            countries: 'themes/settings/countries',
        }),
    },
    watch: {
        countries(value) {
            if (value && value?.length > 0) {
                const countryCode = this.$cookie.get('country_code')
                const { code2 } = value[0]
                if (code2) {
                    this.form.country_code = countryCode || code2
                    this.countryChange(countryCode || code2)
                }
            }
        },
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            if (this.isInternational) {
                this.country = this.supportCountries[0]?.country_code
            } else {
                this.country = 'LA'
            }
            this.form.currency = this.supportCountries[0]?.default_currency
        }
    },
    async mounted() {
        await this.$store.dispatch('banks/banks')
        if (!this.countries.length)
            await this.$store.dispatch('themes/settings/countries')
        else {
            const countryCode = this.$cookie.get('country_code')
            const { code2 } = this.countries[0]
            if (code2) {
                this.form.country_code = countryCode || code2
                this.countryChange(countryCode || code2)
            }
        }
    },
    methods: {
        async register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    try {
                        this.loading = true
                        this.form.recaptchaToken = await this.getRecaptchaToken(
                            'register'
                        )

                        this.form.registrationOption = this.registrationOption
                        const res = await this.$store.dispatch(
                            'auth/registration',
                            this.form
                        )

                        if (res) {
                            this.modal = false
                            this.$emit('onCloseModal')
                            this.$router.push('/player/deposits')
                        } else {
                            this.resetRecaptcha()
                        }
                        this.loading = false
                    } catch (error) {
                        this.loading = false
                    }
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
        countryChange(e) {
            this.$store.dispatch('banks/banks', { country_code: e })
            const selectedCountry = this.countries.find((el) => el.code2 === e)
            if (selectedCountry) {
                const countryCurrency = selectedCountry.currency_code
                this.form.currency = countryCurrency

                // fire to check suspend and player registration status
                this.onChangeCurrency(countryCurrency)
            }
        },
        onChangeCurrency(currency) {
            this.checkSuspendRegistration(currency)
            this.checkRegistrationSetting(currency)
        },
        async checkSuspendRegistration(currency) {
            this.suspendRegistrationLoading = true
            const response = await this.$store.dispatch(
                'themes/player/checkSuspendRegistration',
                currency
            )

            if (response) {
                const { status } = response
                this.currencyIsSuspended = status
                if (status) {
                    this.$toast.error(this.tl('registrationIsNotAvailable'))
                }
            }
            this.suspendRegistrationLoading = false
        },
        async checkRegistrationSetting(currency) {
            this.registrationOptionLoading = true
            const response = await this.$store.dispatch(
                'themes/player/checkRegistrationSetting',
                currency
            )

            if (response) {
                const { registration_type: regType } = response
                if (regType) {
                    if ([2, 3, 4].includes(~~regType)) {
                        this.accountNameReadonly = false
                    } else {
                        this.accountNameReadonly = true
                    }

                    this.registrationOption = ~~regType
                }
            }
            this.registrationOptionLoading = false
        },
        async getAccountHolderName() {
            if (!this.form.bank_id || !this.form.bank_account_number) return
            this.accountHolderLoading = true
            const result = await this.$store.dispatch(
                'themes/player/getBankAccountHolderName',
                {
                    bankId: this.form.bank_id,
                    accountNo: this.form.bank_account_number,
                }
            )
            this.accountHolderLoading = false
            if (result) {
                if (!result.requireManualInput) {
                    this.form.account_holder_name = result?.fullName
                    this.accountNameReadonly = true
                    this.showAccountHolderNameInstruction = false
                    return
                }
                this.accountNameReadonly = false
                this.showAccountHolderNameInstruction = true
            }
            this.form.account_holder_name = ''
        },
        onChangeBank(bankId) {
            this.getAccountHolderName()
            const getSelectedBank = this.banks.find((el) => el.id === bankId)
            if (getSelectedBank?.supported_currency) {
                this.currency = getSelectedBank.supported_currency
            }
        },
    },
}
