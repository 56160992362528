
import playerProfile from '~/mixins/common/pages/player-profile'

export default {
    name: 'InviteAndEarn',
    mixins: [playerProfile],
    data: () => ({
        tab: 1,
        items: [
            {
                player_id: 'player1',
                win_loss: 40,
                commission: 0,
                created_at: '2023-01-01 19:00:00',
            },
            {
                player_id: 'player2',
                win_loss: 40,
                commission: 0,
                created_at: '2023-01-01 19:00:00',
            },
        ],
    }),
    computed: {
        tableHeader() {
            return [
                { text: 'Registration date', value: 'created_at' },
                { text: this.tl('playerId'), value: 'player_id' },
                { text: 'Bet Win/Loss', value: 'win_loss' },
                { text: 'Commission', value: 'commission' },
            ]
        },
    },
}
