
import { mapState } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'DesktopGamePage',
    mixins: [cms, playGames],
    data: () => ({
        gameList: [],
    }),
    computed: {
        ...mapState({
            menubars: (state) => state.cms.menus.menus,
        }),
    },
    watch: {
        '$route.query.idx'(value) {
            this.filterGame(value)
        },
        menubars(value) {
            if (value) {
                const games = this.getVisibleItems(value)

                if (games.length > 0) {
                    const items = games[this.$route.query?.idx].items.map(
                        (data) => {
                            return {
                                ...data,
                                component_code:
                                    games[this.$route.query?.idx]
                                        .component_code,
                            }
                        }
                    )
                    this.gameList = items
                }
            }
        },
    },
    mounted() {
        if (this.$route?.query?.idx) {
            this.filterGame(this.$route?.query?.idx)
        }
    },
    methods: {
        filterGame(id) {
            let index = id
            if (id === null) {
                index = 0
            }
            const items = this.getVisibleItems(this.menubars)[
                index
            ]?.items?.map((data) => {
                return {
                    ...data,
                    component_code: this.getVisibleItems(this.menubars)[index]
                        .component_code,
                }
            })
            this.gameList = items
        },
        changeGame() {
            alert('Game changed')
        },
    },
}
