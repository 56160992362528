
import { mapState, mapGetters } from 'vuex'
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            steppers: 1,
            valid: true,
            form: {
                currency: null,
                network: 'TRC20',
            },
            input_value: null,
            exchange_amount: null,
            depositLoading: false,
            receiverBankInfo: {},
            amount: '0',
            bank: {},
            selectedWallet: null,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            checkExist: (state) => state.themes.deposit.checkExist,
        }),
        ...mapGetters({
            cryptoCurrencies: 'settings/cryptoCurrencies',
            exchangeRates: 'themes/settings/exchangeRate',
        }),
        amountRules() {
            return [
                (v) => !!v || this.tl('isRequired'),
                (v) =>
                    (v &&
                        parseFloat(v?.replace(/,/g, '')) >=
                            parseFloat(this.bank?.deposit_min)) ||
                    `${this.tl('minDepositIs')} ${this.formatCurrencyJS(
                        this.bank?.deposit_min
                    )}`,
                (v) =>
                    (v &&
                        parseFloat(v?.replace(/,/g, '')) <=
                            parseFloat(this.bank?.deposit_max)) ||
                    `${this.tl('maxDepositIs')} ${this.formatCurrencyJS(
                        this.bank?.deposit_max
                    )}`,
            ]
        },
    },
    watch: {
        checkExist(value) {
            // if (value) {
            //     this.isExist()
            // }
        },
        'form.currency'(value) {
            const rate = this.exchangeRates?.find(
                (el) => el.from_currency === value
            )
            this.bank.rate = rate?.rate
            const minMax = this.cryptoCurrencies?.find(
                (el) => el.code === value
            )
            this.bank.deposit_max = minMax?.deposit_max
            this.bank.deposit_min = minMax?.deposit_min
        },
        amount(value) {
            if (!value) return
            if (value === '0') return
            const data = value?.replace(/,/g, '')
            this.input_value = this.formatCurrencyJS(
                parseFloat(this.bank?.rate) * parseFloat(data)
            )
        },
    },
    mounted() {
        // this.isExist()
        // this use only for testing purposes
        this.$store.dispatch('themes/settings/getExchangeRate')

        this.$store.dispatch('settings/currency', { type: 2 })
    },
    methods: {
        onSelectedWallet(id) {
            if (this.selectedWallet === id) {
                this.selectedWallet = null
            } else {
                this.selectedWallet = id
            }
        },
        isExist() {
            if (
                this.checkExist &&
                this.checkExist.is_exist === true &&
                this.checkExist.payment_provider_code === 'crypto'
            ) {
                this.receiverBankInfo = { ...this.checkExist }
                this.receiverBankInfo.deposit_amount = this.formatCurrencyJS(
                    this.checkExist.deposit_amount
                )
                if (
                    this.checkExist?.created_at &&
                    this.checkExist.deposit_status === 1
                ) {
                    this.steppers = 2
                } else if ([2, 3, 4].includes(this.checkExist.deposit_status)) {
                    this.steppers = 3
                }
            } else {
                this.steppers = 1
            }
        },
        onNext() {
            this.steppers++
        },
        async onConfirmDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/confirm',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers++
            }
            this.depositLoading = false
        },
        async onRevokeDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/revoke',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers--
                this.receiverBankInfo = {}
                this.form = {}
                this.input_value = '0'
                this.amount = '0'
                this.form.network = 'TRC20'
                this.$emit('onConfirmDeposit', {
                    type: 'crypto_transfer',
                    value: false,
                })
            }
            this.depositLoading = false
        },
        onInputAmount(amount) {
            if (isNaN(amount?.replace(/,/g, ''))) {
                this.form.amount = '0'
                return
            }
            this.amount = amount
                ? Number.parseFloat(amount?.replace(/,/g, ''))?.toLocaleString()
                : ''
            this.form.amount = this.amount?.toString()?.replace(/,/g, '')
        },
        clearForm() {
            this.form = {}
            this.amount = '0'
            this.input_value = '0'
        },
    },
}
