
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            dragXStart: 0,
            promotionItemWidth: 310,
            checkInterest: false,
        }
    },
    computed: {
        ...mapGetters({
            promotion: 'cms/promotion',
        }),
    },
    methods: {
        getPromotionContentWidth() {
            const client = process.client
            if (this.isMobileOnly()) return
            if (client) {
                // Get promotion content width when size change
                const val = this.$refs?.refPromotion?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow right icon above
                    this.promotionItemWidth = Math.ceil(val / 4) - 14
                }
            } else {
                setTimeout(() => {
                    this.getPromotionContentWidth()
                }, 1000)
            }
        },
    },
}
