
import { mapGetters } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
export default {
    name: 'LoginPage',
    mixins: [fingerprint, ReCaptcha],
    data: () => ({
        modal: true,
        loginType: 'phone',
        showPassword: false,
        form: {},
        loading: false,
        rememberMe: true,
        country: 'LA',
    }),
    computed: {
        ...mapGetters({
            supportCountries: 'themes/settings/supportCountry',
        }),
    },
    watch: {
        supportCountries(value) {
            if (value?.length) {
                if (this.isInternational) {
                    this.country = value[0]?.country_code
                } else {
                    this.country = 'LA'
                }
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            this.country = this.supportCountries[0]?.country_code
        }
    },
    async mounted() {
        const username = this.$cookie.get('un')
        if (username) this.form.player_id = username
        const phoneNumber = this.$cookie.get('pn')
        if (phoneNumber) this.form.phone = phoneNumber
        this.form.reg_fingerprint = await this.visitorId()
    },
    methods: {
        async login() {
            this.loading = true
            try {
                if (this.loginType === 'username') {
                    delete this.form.phone
                } else if (this.loginType === 'phone') {
                    delete this.form.player_id
                } else {
                    return this.$toast.success('Wrong login option')
                }
                this.form.rememberMe = this.rememberMe
                this.form.recaptchaToken = await this.getRecaptchaToken()
                const response = await this.$store.dispatch(
                    'auth/login',
                    this.form
                )
                if (!response) {
                    this.resetRecaptcha()
                }
            } catch (error) {}
            this.loading = false
        },
    },
}
