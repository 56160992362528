
import { mapGetters } from 'vuex'
export default {
    name: 'MyDepositDesktop',
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            companyBankAccounts: 'themes/company-bank/companyBankAccount',
        }),
    },
}
