
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'MyDepositDesktop',
    data() {
        return {
            successModal: false,
            forceToAddBankAccountModal: false,
            disabledToDeposit: false,
            paymentType: null,
            disabledBank: true,
            disabledThirdParty: true,
            disabledCrypto: true,
            type: 2,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            depositFlow: (state) => state.themes.deposit.depositFlow,
            checkExist: (state) => state.themes.deposit.checkExist,
            checkDeposit: (state) => state.themes.deposit.checkDeposit,
        }),
        ...mapGetters({
            depositInstruction: 'cms/depositInstruction',
            companyBankAccounts: 'themes/company-bank/companyBankAccount',
        }),
        depositType() {
            if (!this.depositFlow.deposit_type) return null
            return ~~this.depositFlow.deposit_type
        },
        showBankPayment() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_bank_transfer_enabled
        },
        showOnlinePayment() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_third_party_payment_enabled
        },
        showCryptoCurrency() {
            if (this.depositType === 1) return false
            return this.depositFlow.is_crypto_payment_enabled
        },
    },
    watch: {
        player(value) {
            this.checkWebsiteSettings()
        },
        depositFlow(value) {
            if (value?.is_bank_transfer_enabled) {
                this.paymentType = 'bank'
            } else if (value?.is_crypto_payment_enabled) {
                this.paymentType = 'crypto'
            }
        },
        checkExist(value) {
            if (value?.is_exist) {
                this.enableDisabledPaymentMethod(value)
            }
        },
    },
    async mounted() {
        this.isExit()
        let params = {}
        // this is hardcoded section, since only VRU66 doesn't need currency params
        // Ask Mr Bank about this
        if (this.isInternational) {
            params = { currency: this.$cookie.get('player')?.currency }
        }
        await this.$store.dispatch(
            'themes/deposit/depositFlow',
            this.$cookie.get('player')?.currency
        )
        await this.$store.dispatch(
            'themes/company-bank/getCompanyBankPlayerAccount',
            params
        )

        this.checkWebsiteSettings()

        this.subscribeAction = this.$store.subscribeAction(
            (mutation, state) => {
                if (
                    mutation.type === 'sockets/revokeDepositByAdmin' ||
                    mutation.type === 'sockets/confirmDepositByAdmin' ||
                    mutation.type === 'sockets/successfulDeposit'
                ) {
                    this.isExit()
                }
            }
        )
    },
    beforeDestroy() {
        // unsubscribe the store action
        if (this.subscribeAction) {
            this.subscribeAction()
        }
    },
    methods: {
        // check website settings conditions
        checkWebsiteSettings() {
            const hasBankAccount = this.player.has_bank_account
            const registrationType = this.player.registration_type

            if (hasBankAccount === false && registrationType === 3) {
                this.forceToAddBankAccountModal = true
                this.disabledToDeposit = true
            }
        },
        async isExit() {
            const isExist = await this.$store.dispatch(
                'themes/deposit/checkExist'
            )
            this.enableDisabledPaymentMethod(isExist)
        },
        enableDisabledPaymentMethod(data) {
            if (
                data &&
                data.is_exist === true &&
                data.payment_provider_code === 'manual_transfer'
            ) {
                this.paymentType = 'bank'
                this.disabledBank = false
                this.disabledThirdParty = true
                this.disabledCrypto = true
            } else if (
                data &&
                data.is_exist === true &&
                data.payment_provider_code === 'crypto'
            ) {
                this.paymentType = 'crypto'
                this.disabledBank = true
                this.disabledThirdParty = true
                this.disabledCrypto = false
            } else if (
                data &&
                data.is_exist === true &&
                data.payment_provider_code === 'third_party'
            ) {
                this.paymentType = 'online'
                this.disabledBank = true
                this.disabledThirdParty = false
                this.disabledCrypto = true
            } else {
                this.disabledBank = false
                this.disabledThirdParty = false
                this.disabledCrypto = false
            }
        },
        bankTransferConfirm(value = true) {
            this.disabledThirdParty = value
            this.disabledCrypto = value
        },
        cryptoTransferConfirm(value = true) {
            this.disabledThirdParty = value
            this.disabledBank = value
        },
        onlinePaymentConfirm(value = true) {
            this.disabledCrypto = value
            this.disabledBank = value
        },
        onConfirmDepositInChildrenComponent(data) {
            const { type, value } = data
            if (type === 'bank_transfer') {
                this.bankTransferConfirm(value)
                return
            }
            if (type === 'crypto_transfer') {
                this.cryptoTransferConfirm(value)
                return
            }
            if (type === 'online_payment') {
                this.onlinePaymentConfirm(value)
            }
        },
    },
}
